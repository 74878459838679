import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { toParamsDict, toQueryString } from '../shared/utils/search-params';
import { omit } from 'underscore';
import { ILoginForm } from '../core/authentication/login/login-form.interface';
import { environment } from '../../environments/environment';
import { ErrorHandlingService } from './error-handling.service';
import { UserService } from './user.service';
import { OrganisationService } from './organisation.service';
import { InitPayload } from './initialize.service';
import { BroadcastService } from './broadcast.service';
import { AnalyticsService } from './analytics.service';

declare var ChurnZero: any;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _loggedOutSbj = new Subject<void>();
  readonly loggedOut$: Observable<void> = this._loggedOutSbj;

  private _authSbj = new BehaviorSubject<boolean>(false);
  readonly isAuthenticated$ = this._authSbj.pipe(
    distinctUntilChanged(),
  );

  public hasOverWritingToken = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private analytics: AnalyticsService,
    private userService: UserService,
    private orgService: OrganisationService,
    private broadcastService: BroadcastService,
    private errorHandlingService: ErrorHandlingService,
  ) {
    const queryParams = toParamsDict(location.href);
    // console.log('[REDIRECT] queryParams', queryParams);
    const overwritingToken = queryParams['overwritingToken'];
    const orgCode = queryParams['orgCode'];

    // login via link
    if (overwritingToken) {
      this.hasOverWritingToken = true;
      // flag that this tracker has been called
      sessionStorage.setItem('adminLogin', 'true');
      this.analytics.track('A-login-succeeded', {
        type: 'admin'
      });
      localStorage.clear();
      if (queryParams.adminUsername) {
        localStorage.setItem('adminLogin', 'true'); // for Identify call property
        localStorage.setItem('adminUsername', queryParams.adminUsername)
      }

      this.setToken(overwritingToken);
      // todo set org code
      this.setOrgCode(orgCode);

      // remove overwritingToken from queryParams
      const newParams = omit(queryParams, 'overwritingToken');
      const newPath = location.pathname + toQueryString(newParams);
      // console.log('[REDIRECT] newPath', newPath);
      window.history.replaceState(null, null, newPath);
    }

    if (localStorage.getItem('satellizer_token')) {
      this._authSbj.next(true);
    }
  }

  public setOrgCode(code: string) {
    localStorage.setItem('orgcode', code);
  }

  public renewToken(orgcode: string): Observable<any> {
    return this.http.get(`${environment.accountServiceEndpoint}/orgs/byCode/${orgcode}/renewToken`)
      .pipe(
        tap((res: any) => {
          this.setToken(res.token);
          this.setOrgCode(orgcode);
        }),
        map(res => {
          const initPayload: InitPayload = {
            currentContract: res.currentContract,
            org: res.org,
            user: res.user,
            orgUser: res.orgUser
          };
          return initPayload;
        }),
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  public generateNewToken(orgcode: string): Observable<any> {
    return this.http.get(`${environment.accountServiceEndpoint}/orgs/byCode/${orgcode}/generateToken`)
      .pipe(
        tap((res: any) => {
          this.setToken(res.token);
          this.setOrgCode(orgcode);
        }),
        map(res => {
          const initPayload: InitPayload = {
            currentContract: res.currentContract,
            org: res.org,
            user: res.user,
            orgUser: res.orgUser
          };
          return initPayload;
        }),
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  public login(user: ILoginForm): Observable<any> {
    return this.http.post(`${environment.accountServiceEndpoint}/orgs/byCode/${user.orgcode}/signIn`, { username: user.username, password: user.password })
      .pipe(
        tap((res: any) => {
          this.setToken(res.token);
          this.setOrgCode(user.orgcode);
        }),
        map(res => {
          const initPayload: InitPayload = {
            currentContract: res.currentContract,
            org: res.org,
            user: res.user,
            orgUser: res.orgUser
          };
          return initPayload;
        }),
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  private _logout(): Observable<void> {
    return new Observable((observer) => {
      // localStorage.removeItem('satellizer_token');
      // localStorage.removeItem('orgcode');
      localStorage.clear();
      this._authSbj.next(false);
      observer.next();
      observer.complete();
    });
  }

  public logout(redirect: boolean = true): Observable<any> {
    let logoutHttpError;
    return this.http.get(environment.accountServiceEndpoint + '/signOut', { responseType: 'text' }).pipe(
      catchError((e) => {
        logoutHttpError = e;
        return of(null);
      }),
      switchMap(() => this._logout()),
      switchMap(() => {
        this.analytics.reset();
        // console.log('🥝');
        return redirect ? window.location.href = '/auth/login' : of(null);
      }),
      tap(() => {
        this.stopChurnZero();
        this.broadcastService.broadcast('logout');
        this._loggedOutSbj.next();
      }),
      map(() => { // catch but pass error information to caller
        return logoutHttpError ? { logoutHttpError } : {};
      }),
    );
  }

  public resetPassword(email: any): Observable<any> {
    return this.http
      .post(`${environment.accountServiceEndpoint}/lms/reset`, email)
      .pipe(catchError(this.errorHandlingService.handleHttpError));
  }

  public checkResetToken(token) {
    return this.http
      .post(environment.accountServiceEndpoint + '/resetToken', { token: token })
      .pipe(catchError(this.errorHandlingService.handleHttpError));
    // .toPromise();
  }

  public resetPasswordWithToken(orgcode, token, password): Observable<any> {
    return this.http
      .post(`${environment.accountServiceEndpoint}/orgs/byCode/${orgcode}/resetPassword`, { token: token, password: password })
      .pipe(
        tap((res: any) => {
          this.setToken(res.token);
          this.setOrgCode(orgcode);
        }),
        map(res => {
          const initPayload: InitPayload = {
            currentContract: res.currentContract,
            org: res.org,
            user: res.user,
            orgUser: res.orgUser
          };
          return initPayload;
        }),
        catchError(this.errorHandlingService.handleHttpError));
  }

  public isAuthenticated() {
    return this._authSbj.value;
  }

  public getToken() {
    return localStorage.getItem('satellizer_token');
  }

  public removeToken() {
    localStorage.removeItem('satellizer_token');
  }

  public setToken(token) {
    localStorage.setItem('satellizer_token', token);
    this._authSbj.next(true);
  }

  private stopChurnZero() {
    if (environment.production) {
      ChurnZero.push(['trackEvent', 'Logout']);
      ChurnZero.push(['stop']);
    }

  }

}
