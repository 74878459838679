import { Component, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgbDateAdapter, NgbDateParserFormatter, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';
import { ISingleSelectorOptions } from 'src/app/form-builder-tool/fb-side-panel/fb-section-editor/fb-question-card/custom-single-selector/custom-single-selector.component';
import { BarSquareRatingComponent } from 'src/app/shared/bar-square-rating/bar-square-rating.component';
import { ChecklistModelDirective } from 'src/app/shared/directives/checklist-model.directive';
import { CustomDateParserFormatter } from 'src/app/shared/utils/custom-date-parser-formatter';
import { IPerformanceReviewTaskQuestion } from '../../../../../../services/performance-review.service';
import { CustomDateAdapter } from 'src/app/shared/decorator/date-picker/customDateAdapter';

/**
 * a UI component to display organisation questions form.
 * It has input mode and disabled/display mode.
 */
@Component({
  selector: 'app-org-questions-form',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ChecklistModelDirective,
    BarSquareRatingComponent,
    MatDatepickerModule,
    NgbInputDatepicker,
    UIStreamlineIconComponent,
  ],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomDateAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ],
  templateUrl: './org-questions-form.component.html',
  styleUrl: './org-questions-form.component.scss'
})
export class OrgQuestionsFormComponent {
  @Input() questions: IPerformanceReviewTaskQuestion[];
  @Input() answersFormGroup: FormGroup;
  @Input() commentsFormGroup?: FormGroup;
  @Input() additionalNotesFormGroup: FormGroup;
  /**
   * a property to indicate the display or input mode.
   */
  @Input() disabled = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.answersFormGroup || changes.commentsFormGroup || changes.additionalNotesFormGroup) {
      if (this.disabled) {
        this.answersFormGroup.disable();
        this.commentsFormGroup?.disable();
        this.additionalNotesFormGroup?.disable();
      }
    }
  }

  generateDropdownOptions(question: IPerformanceReviewTaskQuestion): ISingleSelectorOptions<string>[] {
    const options = question.options || [];

    const singleSelectorOptions = options.map((option) => {
      return {
        value: option,
        display: option,
      };
    });

    return singleSelectorOptions;
  }

  handleErrorMessage(question: IPerformanceReviewTaskQuestion): string | null {
    const control = this.answersFormGroup?.controls[question?.questionID];
    const errors = control?.errors;

    if (!control) return null;

    switch (question.qType) {
      case 'private-reflection':
        if (errors?.maxlength) {
          return 'You are over the character limit.';
        }
        if (errors?.required) {
          return 'Answer is required.';
        }
        break;
      case 'number':
        if (errors?.max || errors?.min) {
          return 'Please provide a valid number.';
        }
        if (errors?.required) {
          return 'Answer is required.';
        }
        break;
      case 'date-picker':
        if (errors?.required) {
          return 'Date is required.';
        }
        break;
      case 'dropdown':
      case 'multi-answers':
      case 'single-answer':
      case 'short-answer':
      case 'ratings':
        if (errors?.required) {
          return 'Answer is required.';
        }
        break;
      default:
        return;
    }

    return !control.pristine && !control.valid ? 'Please provide a valid answer.' : null;
  }


  showCalendarAndHideYearSelect(datePicker: NgbInputDatepicker) {
    datePicker.toggle();
    setTimeout(() => {
      const selectorsParent = document.querySelector(`.ngb-dp-navigation-select`);
      if (selectorsParent) {
        selectorsParent.removeChild(selectorsParent.lastChild);
      }
    }, 30);
  }
}