import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ViewCell } from 'src/app/shared-modules/ng2-smart-table/components/cell/cell-view-mode/view-cell';
import { USER_STATUS, UserService } from '../../../services/user.service';
import { getGeneralContext, setContext } from '../../utils/get-analytics-body';
import { getMaxWidth } from '../../utils/table-cell-functions';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { RouterLink } from '@angular/router';
import { AvatarComponent } from '../avatar/avatar.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'staff-link-display',
  template: `
      <div *ngIf="fullName" class="d-flex justify-content-between" [style]="'width: ' + maxWidth + 'px;'">
        <div class="d-flex align-items-center children-gap-2 text-nowrap text-truncate" >
          @if(!withoutAvatar){
            <ui-avatar [size]="avatarSize" [fullName]="fullName" [url]="profilePicURL" bgColor="pink-100" textColor="text-pink-700"></ui-avatar>
          }
          <div #text class="text-truncate text-nowrap" [style]="hasNewBadge ? 'max-width: 130px;' : ''">
            <ng-container *ngIf="isManaged">
              @if(noLink){
                <span class="fw-medium text-neutral-900" [style]="textStyle">{{displayedFullName}}</span>
              }@else {
                <ng-container *ngIf="!openNewTab">
                  <a class="fw-medium text-neutral-900 d-flex" href="javascript:void(0)" *ngIf="!isNewTab"
                    [routerLink]="getTranscriptLink()"
                    [fragment]="fragment"
                    [ngbTooltip]="list" [tooltipClass]="'custom-tooltip'" [placement]="'top-left auto'"
                    [disableTooltip]="!showTooltip"
                    [container]="'body'"
                    (click)="setAnalyticsContext()"
                  ><span class="fw-medium text-neutral-900" [style]="textStyle">{{displayedFullName}}</span></a>
                  <a class="fw-medium text-neutral-900 d-flex" href="javascript:void(0)" *ngIf="!!isNewTab"
                    [routerLink]="getTranscriptLink()"
                    [fragment]="fragment"
                    [ngbTooltip]="list" [tooltipClass]="'custom-tooltip'" [placement]="'top-left auto'"
                    [disableTooltip]="!showTooltip" [container]="'body'"
                    (click)="setAnalyticsContext()"
                    ><span class="fw-medium text-neutral-900" [style]="textStyle">{{displayedFullName}}</span></a>
              </ng-container>
              <ng-container *ngIf="openNewTab">
                  <a class="fw-medium text-neutral-900 d-flex" target="_blank" *ngIf="!isNewTab"
                    [routerLink]="getTranscriptLink()"
                    [fragment]="fragment"
                    [ngbTooltip]="list" [tooltipClass]="'custom-tooltip'" [placement]="'top-left auto'"
                    [disableTooltip]="!showTooltip" [container]="'body'"
                    (click)="setAnalyticsContext()"
                  >{{displayedFullName}}</a>
                  <a class="fw-medium text-neutral-900 d-flex" target="_blank" *ngIf="!!isNewTab"
                    [routerLink]="getTranscriptLink()"
                    [fragment]="fragment"
                    [ngbTooltip]="list" [tooltipClass]="'custom-tooltip'" [placement]="'top-left auto'"
                    [disableTooltip]="!showTooltip" [container]="'body'"
                    (click)="setAnalyticsContext()"
                  >{{displayedFullName}}</a>
              </ng-container>
              }
            </ng-container>

            <span *ngIf="!isManaged" class=" text-neutral-{{isLmsUser ? '900' : '600'}} d-flex">{{fullName}}</span>
          </div>
      </div>
      <span *ngIf="hasNewBadge" class="badge badge-primary align-self-center">New</span>
    </div>
    <span *ngIf="!fullName">System</span>
    <ng-template #list>
        <div class="d-flex flex-column">
          <p class="text-white f-small">{{fullName}}</p>
        </div>
    </ng-template>



  `,
  standalone: true,
  imports: [
    NgIf,
    AvatarComponent,
    RouterLink,
    NgbTooltip,
  ],
})
export class StaffLinkDisplayComponent implements ViewCell, OnInit, AfterViewInit, OnChanges {

  userID: string;
  orgUserID: string;
  fullName: string;
  displayedFullName: string;
  profilePicURL: string;
  status: string;
  openNewTab = false;
  isNewTab: boolean;
  @Input() maxWidth = 216;
  showTooltip = false;
  isModal = false;
  className = 'orgUser';
  hasNewBadge = true;
  isManaged = true;
  isLmsUser = false;

  @Input() value: any;
  @Input() rowData: any;
  @Input() fragment = null;
  @Input() textStyle = '';
  @Input() isHierarchyPage = false;
  @Input() alwaysShowName = false;
  @Input() avatarSize = 'sm';
  @Input() noLink = false;
  @Input() withoutAvatar = false;
  @Input() showCurrentUser = false;
  @ViewChild('text') text;
  globalFullName: string;


  constructor(
    private _cdr: ChangeDetectorRef,
    private userService: UserService
  ) {
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  ngOnInit() {
    this.maxWidth = getMaxWidth(this.className, this.isModal, this.maxWidth) || this.maxWidth;
    if (this.avatarSize === 'lg') {
      this.maxWidth = 400;
    }

    if (!this.value || !(this.value.fullName) || !(this.value.orgUserID)) {
      this.userID = this.rowData.userID;
      this.orgUserID = this.rowData.orgUserID;
      this.fullName = this.rowData.fullName;
      this.profilePicURL = this.rowData.profilePicURL;
      this.status = this.rowData.status;
      this.isNewTab = this.rowData.isNewTab;
      // return;
    } else {
      this.userID = this.value.userID;
      this.orgUserID = this.value.orgUserID;
      this.fullName = this.value.fullName;
      this.profilePicURL = this.value.profilePicURL;
      this.status = this.value.status;
      this.isNewTab = this.value.isNewTab;
      this.openNewTab = this.openNewTab || this.value.openNewTab;
    }
    this.userID = this.userID || this.value?.userID // in case user is not managed but still want to display name
    this.hasNewBadge = this.rowData?.hasNewBadge;
    this.isManaged = !!this.userService.managedOrgUserDictionaryByOrgUserID[(this.orgUserID)];
    this.globalFullName = this.userService.getFullNameGlobally({orgUserID: this.orgUserID, userID: this.userID});
    this.isLmsUser = this.userService.plainOrgUser.userID === this.userID;
    if (!this.isManaged && this.isLmsUser) {
      this.fullName = this.globalFullName; // show lms user's own name
    }

    this.displayedFullName = this.getDisplayedFullName();
  }

  getTranscriptLink() {
    if (this.isHierarchyPage) {
      return ['/pages/admin/hierarchy', this.orgUserID]
    }
    return ['/pages/staff-transcript', this.orgUserID];
  }

  /**
  * displayedFullName may include ' (You)'
  */
  getDisplayedFullName() {
    const isCurrentUser = this.userService.orgUser.value.orgUserID === this.orgUserID;

    if (this.showCurrentUser && isCurrentUser) {
      return `${this.fullName} (You)`;
    } else {
      return `${this.fullName}`;
    }
  }

  ngAfterViewInit() {
    if (!this.text || this.avatarSize === 'lg') {
      return;
    }
    const el = this.text.nativeElement;
    if (el.scrollWidth > el.clientWidth) {
      this.showTooltip = true;
      this._cdr.detectChanges();
    }
  }

  // analytics
  setAnalyticsContext() {
    // console.log(getGeneralContext(this.rowData));
    setContext('userViewed', getGeneralContext(this.rowData));
  }
}

