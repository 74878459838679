import { DATE_OPTION_ENUM } from "src/app/pages/reporting/filter-pack/interfaces";

export function getAllDateOptions(): DATE_OPTION_ENUM[] {
    return [
        DATE_OPTION_ENUM.custom,
        DATE_OPTION_ENUM.lastWeek,
        DATE_OPTION_ENUM.lastMonth,
        DATE_OPTION_ENUM.lastQuarter,
        DATE_OPTION_ENUM.lastYear,
        DATE_OPTION_ENUM.last365Days,
        DATE_OPTION_ENUM.last180Days,
        DATE_OPTION_ENUM.last90Days,
        DATE_OPTION_ENUM.last30Days,
        DATE_OPTION_ENUM.last14Days,
        DATE_OPTION_ENUM.last7Days,

        DATE_OPTION_ENUM.thisWeekSoFar,
        DATE_OPTION_ENUM.thisMonthSoFar,
        DATE_OPTION_ENUM.thisQuarterSoFar,
        DATE_OPTION_ENUM.thisYearSoFar,
        DATE_OPTION_ENUM.thisWeek,
        DATE_OPTION_ENUM.thisMonth,
        DATE_OPTION_ENUM.thisQuarter,
        DATE_OPTION_ENUM.thisYear,
        DATE_OPTION_ENUM.nextWeek,
        DATE_OPTION_ENUM.nextMonth,
        DATE_OPTION_ENUM.nextQuarter,
        DATE_OPTION_ENUM.nextYear,
        DATE_OPTION_ENUM.next365Days,
        DATE_OPTION_ENUM.next180Days,
        DATE_OPTION_ENUM.next90Days,
        DATE_OPTION_ENUM.next30Days,
        DATE_OPTION_ENUM.next14Days,
        DATE_OPTION_ENUM.next7Days,
        DATE_OPTION_ENUM.allTimeReport,
    
    ]
}

export function getDateOptionDisplay(option: DATE_OPTION_ENUM, isFullDateRange=false) {
    // if (option === DATE_OPTION_ENUM.thisYear) {
    //   return 'Year to Date';
    // }
    if (option === DATE_OPTION_ENUM.allTimeReport) {
        return 'All Time';
    }
    if (option === DATE_OPTION_ENUM.lastYear) {
        return 'Last Calendar Year';
    }
    if (option === DATE_OPTION_ENUM.thisYear) {
        return 'This Calendar Year';
    }
    if (isFullDateRange) {
        return option;
    }
    return option.startsWith('This') ? option.replace(' To Date', '') + ' so Far' : option;
}
