import { CommonModule, NgFor, NgClass } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, OnInit, Output, SimpleChanges, OnChanges } from '@angular/core';
import { NgbNavModule, NgbNav, NgbNavItem, NgbNavItemRole, NgbNavLink } from '@ng-bootstrap/ng-bootstrap';
import { DownloadCsvButtonComponent } from '@components/table-utilities/download-csv-button/download-csv-button.component';
import { Ng2SmartTableModule } from 'src/app/shared-modules/ng2-smart-table/ng2-smart-table.module';
import { keys, values } from 'underscore';

@Component({
    selector: 'app-multi-tab-tables',
    templateUrl: './multi-tab-tables.component.html',
    styleUrls: ['./multi-tab-tables.component.scss'],
    standalone: true,
    imports: [NgbNav, NgFor, NgbNavItem, NgbNavItemRole, NgbNavLink, NgClass, DownloadCsvButtonComponent, CommonModule]
})
export class MultiTabTablesComponent implements OnChanges {
  @Input() tables: {
    name, numRow,
  }[];
  @Input() tableDic: {[k in ReportTabType]: {name; numRow}}
  @Input() activeTabId;
  @Input() showDownload = false;
  @Output() navChange = new EventEmitter();
  @Output() navKeyChange = new EventEmitter<ReportTabType>()
  @Output() downloadCsv = new EventEmitter();



  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tableDic?.currentValue) {
      this.tables = values(this.tableDic);
    }
  }

  onNavChange(tab) {
    this.activeTabId = tab;
    this.navChange.emit(tab);
    if (this.tableDic) {
      const activeKey = keys(this.tableDic)[tab] as ReportTabType;
      this.navKeyChange.emit(activeKey)
    }
  }
}

export type ReportTabType = 'all' | 'team';



@NgModule({
    imports: [
        CommonModule,
        NgbNavModule,
        Ng2SmartTableModule,
        MultiTabTablesComponent
    ],
    exports: [MultiTabTablesComponent],
})
export class MultiTabTablesModule {
}

