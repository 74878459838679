import { CommonModule } from '@angular/common';
import { Component, Input, effect, output, signal } from '@angular/core';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';

@Component({
  selector: 'app-navigation-tabs',
  standalone: true,
  imports: [NgbNavModule, CommonModule, UIStreamlineIconComponent],
  templateUrl: './navigation-tabs.component.html',
  styleUrl: './navigation-tabs.component.scss'
})
export class NavigationTabsComponent {
  @Input() items: {name; icon?}[];
  @Input() initTab = 0;
  

  activeTabId = signal(0);
  tabClick = output<number>();
  
  constructor() {
    
    effect(() => {
      // console.log('click')
      this.tabClick.emit(this.activeTabId())
    })
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.activeTabId.set(this.initTab);
  }


}
