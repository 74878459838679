import { Directive, EventEmitter, Input, OnChanges, Output, Renderer2, ElementRef, HostListener } from '@angular/core';


@Directive({
    selector: '[checklist]',
    standalone: true,
})
export class ChecklistModelDirective implements OnChanges {
    @Input() checklist: any[];
    @Input() checklistValue: any;
    @Output() checklistChange = new EventEmitter<any[]>();

    constructor(
        private _renderer: Renderer2,
        private el: ElementRef
    ) {

    }

    ////////////

    ngOnChanges() {
        if (this.checklist && this.checklist.indexOf(this.checklistValue) !== -1) {
            this._renderer.setProperty(this.el.nativeElement, 'checked', true);
        } else {
            this._renderer.setProperty(this.el.nativeElement, 'checked', false);
        }
    }

    @HostListener('change', ['$event']) triggerOnChange($event: Event) {
        const target = $event.target as HTMLInputElement;
        let updatedList;

        if (target && target.checked) {
            updatedList = [...this.checklist, this.checklistValue];
        } else {
            const i = this.checklist.indexOf(this.checklistValue);
            updatedList = [...this.checklist.slice(0, i), ...this.checklist.slice(i + 1)];
        }

        this.checklistChange.emit(updatedList);
    }
}
