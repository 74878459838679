<div [class]="theme">
  <div class="rating-units mx-auto">
    @for (unit of units; track $index) {
      <div class="rating-unit"
        [class.rating-selected]="unit.selected" [class.rating-active]="unit.active" [class.is-disabled]="disabled"
        (click)="handleClick(unit.value)">
        {{unit.contain}} 
      </div>
    }

  </div>
  @if (minText || maxText) {
    <div class="d-flex">
      <div class="rating-min-text mr-2">{{minText}}</div>
      <div class="rating-min-max">
        <div class="rating-min-max__up"></div>
        <div class="rating-min-max__down"></div>
      </div>
      <div class="rating-max-text ml-2">{{maxText}}</div>
    </div>
  }

</div>