@if(value) {
  <app-avatar-requirements *ngIf="avatar" [iconType]="avatar.iconType" class="me-1 d-inline-flex"></app-avatar-requirements>
  <ui-streamline-icon *ngIf="slIcon" [slIcon]="slIcon" class="text-pink-700 me-1"></ui-streamline-icon>
  <div *ngIf="width; else link" [style.width]="width + 'px'">
    <app-truncated-text [value]="value" [doRecheck]="true">
      <ng-container *ngTemplateOutlet="link"></ng-container>
    </app-truncated-text>
  </div>

} @else {
  <span>-</span>
}
<ng-template #link>
    @if (displaySubname && rowData.subname) {
      <div class="f-small fw-semibold">{{rowData.subname}}</div>
    }
    <a
    [routerLink]="routerLink" [queryParams]="queryParams"
    class="fw-medium text-neutral-900" (click)="onClick()">
      {{value}}
    </a>
</ng-template>
