import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Input,
  OnInit,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import {PlacementArray} from '@ng-bootstrap/ng-bootstrap/util/positioning';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import { NgIf, NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'ui-tooltip-underline',
    styleUrls: ['./tooltip-underline.component.scss'],
    encapsulation: ViewEncapsulation.None,
    template: `
      <ng-template #list>
          <div class="d-flex flex-column">
              <ng-container *ngIf="overlineText">
                  <p class="tooltip-title">{{ overlineText }}</p>
                  <br>
              </ng-container>
              <p *ngIf="bodyText" class="text-white f-small">{{bodyText}}</p>
              <p *ngIf="bodyTextP2" class="mt-5 text-white f-small">{{bodyTextP2}}</p>
              <ng-container [ngTemplateOutlet]="customizedText" [ngTemplateOutletContext]="context"></ng-container>
          </div>
      </ng-template>

      <span #tooltip="ngbTooltip"
            [ngbTooltip]="bodyText ? list : ''"
            [placement]="placement"
            [tooltipClass]="tooltipClass"
            [triggers]="triggers"
            (click)="manualTriggerHandler(tooltip, $event)"
            [class]="displayTextClass + (inlineBlock ? ' d-inline-block' : '')"
            [closeDelay]="tooltipCloseDelay"
            [container]="container">
          {{displayText}}
      </span>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        NgTemplateOutlet,
        NgbTooltip,
    ],
})
export class TooltipUnderlineComponent implements OnInit {
  @ContentChild("customizedText") customizedText :TemplateRef<any>;
  @Input() overlineText?: string | TemplateRef<any>;
  @Input() bodyText: string | TemplateRef<any>;
  @Input() bodyTextP2: string | TemplateRef<any>;
  @Input() displayText: string | TemplateRef<any>;
  @Input() tooltipClass = 'custom-tooltip';
  @Input() placement = 'top-left auto';
  @Input() triggers: 'hover focus' | 'manual' = 'hover focus';
  @Input() displayTextClass = 'has-help-cursor f-small text-neutral-800 border-dashed-bottom';
  @Input() inlineBlock = true;
  @Input() container?;
  @Input() tooltipCloseDelay?: number = 0;
  @Input() manualTriggerHandler: (tooltip: NgbTooltip, event?: Event) => void = (tooltip) => { tooltip.open(); };

  constructor() {
  }

  ngOnInit(): void {
  }

  get context(){
    return this;
  }
}
