import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CpdTimePipe } from '../shared/pipes/cpd-time.pipe';
import { getByoResourceTypeByResource } from '../shared/utils/get-resource-type';
import { resourceTypeDict } from '../shared/byo-shared/byo-resource-types';
import { COLUMN_VALUES } from '../core/table-headers.dict';
import { isArray, isObject } from 'underscore';

export function rankByPercentNum(direction: any, a = '', b = '') {
  if (typeof a !== 'string' || typeof b !== 'string') {
    throw new TypeError(`Comparing type should be string. Type A: ${typeof a}; Type B: ${typeof b}`);
  } else {
    const percentNumOfA = +a.split('%')[0] ?? 0;
    const percentNumOfB = +b.split('%')[0] ?? 0;
    if (percentNumOfA < percentNumOfB) {
      return -1 * direction;
    }
    if (percentNumOfA > percentNumOfB) {
      return direction;
    }
    return 0;
  }
}
// Utils to handle table columns;
// TODO: configure pipes outside of the service
@Injectable({
  providedIn: 'root'
})

export class TableColumnValuePrepareAndSortUtilsService {
  noRecordSymbol = COLUMN_VALUES.NO_RECORD_SYMBOL;

  constructor() {
  }

  longIDColumn(id = '') {
    return id.substr(0, 8) || '-';
  }

  searchLongID(id = '', search = '') {
    return search ? id.toLowerCase().substr(0, 8).includes(search) : true;
  }

  toLocalDate(date = '', datePipe) {
    if (!datePipe) {
      throw new TypeError('Date Pipe cannot be empty');
    }
    return datePipe.transform(date);
  }

  toCPDTime(min = '', cpdPipe: CpdTimePipe, format?) {
    if (!cpdPipe) {
      throw new TypeError('CPD Pipe cannot be empty');
    }
    return cpdPipe.transform(min, format).replace('None', this.noRecordSymbol);
  }

  transformResourceType(type = '') {
    // Copy from the resource service map
    const resourceDict = {
      onlineResource: 'Lecture',
      blog: 'Article',
      OnlineCourse: 'Course',
      onlineCourse: 'Course',
      explainer: 'Explainer',
      'e-Learning': 'E-Learning'
    };
    return resourceDict[type] || type;
  }

  dateCompareFunction(direction: any, a: any, b: any) {
    if (!a && !b) {
      return 0;
    } else if (!a) {
      return -1 * direction;
    } else if (!b) {
      return direction;
    } else if (new Date(a) < new Date(b)) {
      return -1 * direction;
    } else if (new Date(a) > new Date(b)) {
      return direction;
    } else {
      return 0;
    }
  }

  toCustomisedDateFormat(datePipe, date, format) {
    if (!datePipe) {
      throw new TypeError('Date Pipe Cannot Be Empty');
    }
    return datePipe.transform(date, format);
  }

  userAvatarCompareFunction(direction: any, a: any, b: any) {
    if (a && b) {
      if (a.fullName.toLowerCase() < b.fullName.toLowerCase()) {
        return -1 * direction;
      }
      if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) {
        return direction;
      }
      return 0;
    }

    return 0;
  }

  filterAvatar(displayObj, search?: string): boolean {
    if (isObject(displayObj)) {
      return search === '' || displayObj?.fullName?.toLowerCase().indexOf(search?.toLowerCase()) !== -1;
    } else {
      return false;
    }

  }

  filterByLength(displayObj, search?: string): boolean {
    return search === '' || displayObj?.length.toString() === search;
  }

  singleStringCompareFunction(direction: any, a: any, b: any) {
    if (a?.toLowerCase() < b?.toLowerCase()) {
      return -1 * direction;
    }
    if (a?.toLowerCase() > b?.toLowerCase()) {
      return direction;
    }
    return 0;
  }

  applyPipe(value = '', pipe) {
    if (!pipe) {
      throw new TypeError('Pipe cannot be empty!');
    }
    return pipe.transform(value);
  }

  compareByProperty(property: string = 'title') {
    return function (direction: any, a: any, b: any) {
      const toStr = (val) => {
        if (val && isArray(val)) {
          return (val).map((entry) => entry[property] || '').sort().join(', ').toLowerCase()
        }
        return (val && val[property] && val[property].toLowerCase()) || ''
      }
      const first = toStr(a);
      const second = toStr(b);
      if (!first) {
        return direction;
      }
      if (!second) {
        return -1 * direction;
      }
      if (first < second) {
        return -1 * direction;
      }
      if (first > second) {
        return direction;
      }
      return 0;
    };
  }

  compareByDate() {
    return (direction: any, a: any, b: any) => {
      if (!a && !b) {
        return 0;
      } else if (!a) {
        return -1 * direction;
      } else if (!b) {
        return direction;
      } else if (new Date(a) < new Date(b)) {
        return -1 * direction;
      } else if (new Date(a) > new Date(b)) {
        return direction;
      } else {
        return 0;
      }
    };
  }

  numberCompare() {
    return (direction: any, a: any, b: any) => {
      if (!a) {
        a = 0;
      }
      if (!b) {
        b = 0;
      }
      return (a - b) * direction;
    };
  }

  scheduleFrequencyCompare(direction: any, a: any, b: any) {
    const first = a;
    const second = b;

    if (!first) {
      return direction;
    }
    if (!second) {
      return -1 * direction;
    }
    // 'once only' -> 1st priority
    if (first.indexOf('once only') > -1 && second.indexOf('once only') < 0) {
      return -1 * direction;
    }
    if (first.indexOf('once only') < 0 && second.indexOf('once only') > -1) {
      return direction;
    }
    //  'Due ... every ...' (specific date) -> 2nd priority
    if (first.indexOf('Due every') > -1 && second.indexOf('Due every') < 0) {
      return -1 * direction;
    }
    if (first.indexOf('Due every') < 0 && second.indexOf('Due every') > -1) {
      return direction;
    }
    if (first.indexOf('Due every') < 0 && second.indexOf('Due every') < 0) {
      if (new Date(first.split(' every')[0].split('Due ')[1]) >= new Date(second.split(' every')[0].split('Due ')[1])) {
        return direction;
      } else {
        return -1 * direction;
      }
    }
    //  'Due every' (specific interval) -> 3rd priority
    if (first.indexOf('Due every') > -1 && second.indexOf('Due every') > -1) {
      if (first.split('Due every ')[1] >= second.split('Due every ')[1]) {
        return direction;
      } else {
        return -1 * direction;
      }
    }
    return 0;
  }

  generalCompare() {
    return (direction: any, a: any, b: any) => {
      if (!a) {
        a = '';
      }
      if (!b) {
        b = '';
      }
      a = a.toString()?.toLowerCase() || '';
      b = b.toString()?.toLowerCase() || '';
      if (a < b) {
        return -1 * direction;
      }
      if (a > b) {
        return direction;
      }
      return 0;
    };
  }

  generalCompareWithValuePrepareFun(valuePrepareFun = (v): string => {
    return v;
  }) {
    return (direction: any, a: any, b: any) => {
      a = valuePrepareFun(a)?.toLocaleLowerCase() || '';
      b = valuePrepareFun(b)?.toLocaleLowerCase() || '';
      if (a < b) {
        return -1 * direction;
      }
      if (a > b) {
        return direction;
      }
      return 0;
    };
  }

  transformStringArray(array: string[] = []) {
    return array && array.join(', ');
  }

  searchByProperty(property = 'title') {
    return (cell?: any, search = '') => {
      if (cell) {
        if (this._isArray(cell)) {
          return (cell.map((entry) => entry[property] || '').join(', ').toLowerCase() || '-').includes(search.toLowerCase());
        }
        const value = cell[property] || '-';
        return search === '' || value.toLowerCase().includes(search.toLowerCase());
      }
      return false;
    };
  }

  generalSearch(cell: string | any[] = '', search = '') {
    if (Array.isArray(cell)) {
      return (cell.join('').toLowerCase() || '-').includes(search.toLowerCase());
    }
    return (cell.toLowerCase() || '-').includes(search.toLowerCase());
  }

  generalSearchWithValuePrepareFun(valuePrepareFun = (v): string => {
    return v;
  }) {
    return (cell?: any, search = '') => {
      if (Array.isArray(cell)) {
        return (cell.map(c => valuePrepareFun(c)).join('')) || '-'?.toLowerCase().includes(search.toLowerCase());
      }
      return (valuePrepareFun(cell) || '-')?.toLowerCase().includes(search.toLowerCase());
    };
  }

  searchCPDMin(cpdTimePipe) {
    if (!cpdTimePipe || !cpdTimePipe.transform) {
      throw new TypeError('Please Pass In CPD Time Pipe');
    }
    return (cell = 0, search = '') => {
      if (cell) {
        const cpd = cpdTimePipe.transform(cell);
        // return cell.toString().includes(search) || cpd.includes(search);
        return cpd.includes(search);
      }
      return cell.toString() === search;
    };
  }

  extractByProperty(property = 'title') {
    return cell => {
      if (this._isArray(cell)) {
        return cell.filter(entry => !!entry).map(entry => addQuotes(entry[property]) || '').sort().join(', ') || this.noRecordSymbol;
      }
      return (cell && cell[property]) || this.noRecordSymbol;
    };
  }

  private _isArray(cell): cell is any[] {
    return Array.isArray(cell);
  }

  compareByLength() {
    return (direction: any, a = [], b = []) => {
      if (a && b) {
        if (a?.length < b?.length) {
          return -1 * direction;
        }
        if (a?.length > b?.length) {
          return direction;
        }
        return 0;
      }
      return 0;
    };
  }

  resourceTypeConvert() {
    return cell => {
      if (!cell) {
        return '-';
      }
      if (cell?.orgID || cell?.orgResource) {
        const { value: convertedResourceType, title: resourceTypeDisplay, icon: resourceTypeIcon } = getByoResourceTypeByResource(cell);
        return resourceTypeDisplay || '-';
      } else {
        return cell?.resourceType ? (resourceTypeDict[cell.resourceType] || '-') : (resourceTypeDict[cell?.activityType] || (cell?.activityType || '-'));
      }
    };
  }

  replaceNoRecord() {
    return cell => {
      // console.log(cell);
      if ((typeof cell !== 'number' && !cell?.length) || cell === '') {
        return this.noRecordSymbol;
      }
      return cell;
    };
  }

}

export function addQuotes(s: string) {
  if (s?.includes(',')) {
    return `"${s}"`;
  }
  return s;
}