import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { RouterLink } from '@angular/router';
import { TruncatedTextComponent } from '@components/customised-multi-select/truncated-text/truncated-text.component';
import { CommonModule } from '@angular/common';
import { IUIStreamlineIcon } from '@ui/streamline-icon/streamline-icon-interfaces';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';
import { AvatarRequirementsComponent, CellAvatarType } from '@components/avatar/avatar-requirements/avatar-requirements.component';

@Component({
    selector: 'app-general-linked-cell',
    templateUrl: './general-linked-cell.component.html',
    styleUrls: ['./general-linked-cell.component.scss'],
    standalone: true,
    imports: [RouterLink, TruncatedTextComponent, CommonModule, UIStreamlineIconComponent, AvatarRequirementsComponent]
})
export class GeneralLinkedCellComponent<T> implements OnInit {
  @Input() linkFn: (rowData) => {routerLink, queryParams?};
  @Input() value;
  @Input() rowData: T;
  @Input() rowDataType;
  @Input() width;
  @Input() slIcon?: IUIStreamlineIcon;
  @Input() avatar?: {iconType: CellAvatarType}
  @Input() clickFn?: (rowData) => void;
  @Input() displaySubname = false;
  click$ = new Subject<T>();

  routerLink: any;
  queryParams: any;
  constructor() { }

  ngOnInit(): void {
    if (this.linkFn) {
      this.routerLink = this.linkFn(this.rowData).routerLink;
      this.queryParams = this.linkFn(this.rowData).queryParams;
    }
  }

  onClick() {
    this.click$.next(this.rowData);
    if (this.clickFn) {
      this.clickFn(this.rowData);
    }
  }

}
