export interface ITrainingPlan {
    title: string;
    mtPlanID?: string;
    startDate?: string;
    endDate?: string;
    description?: string;
    status?: PLAN_STATUS_TYPE;
    orgUsers?: string[];
    startDateUTC?: string;
    endDateUTC?: string;
    orgID?: string;
    hiddenDayNum?: number;
    complianceType?: COMPLIANCE_TYPE;
}

export interface IPlanWithStats {
    plan: ITrainingPlan;
    compliancePercent?: string;
    complianceRate?: number;
    complianceType?: COMPLIANCE_TYPE;
    scheduleNum?: number;
    enrolledNum?: number;
    jobRoles?: any[];
    relatedJobRoles?: any[];
    // completion rate
    achievementPercent?: string;
    achievementRate?: number;
    activeEnrolledNum?: number;
    teamStat?: IOrgMTPlanTeamStat;
    unfinishedScheduleNum: number;
}

export enum COMPLIANCE_TYPE {
  compliance = 'Compliance',
  mandatory = 'Mandatory',
  optional = 'Optional',
}

export enum PLAN_STATUS_TYPE {
  active = 'Active',
  draft = 'Draft',
  inactive = 'Inactive',
  archived = 'Archived',
}

export interface IOrgMTPlanTeamStat {
    complianceRate?: number;
    compliancePercent?: string;
    achievementRate?: number;
    activeEnrolledNum?: number;
    enrolledNum?: number;
    totalEnrolledNum?: number;
    unfinishedScheduleNum: number;
    relatedJobRoles?: any[];
}

export interface ITrainingSchedule {
    mtScheduleID?: string;
    mtPlan: string;
    scheduleType: string;
    resourceID: string;
    resourceTitle?: string;
    isPassportEnabled?: boolean;
    resourceType?: string;
    resourceMin?: string;
    providerType: string;
    jobRoles?: string[];
    startDate?: string;
    endDate?: string;
    dueDay?: number;
    dueMonth?: number;
    scheduleDuration?: string;
    assignDuration?: string;
    earlyRecogDuration?: string;
    lateRecogDuration?: string;
    resource?: any;
    order?: number;
    resetOpenDate?: string;
}

export interface IJobRoleEstimatedTime {
    jobRole?: string;
    jobRoleID?: string;
    estimatedMins?: string;
}


export interface UTC {
    assignDateUTC: string;
    dueDateUTC: string;
    earlyRecogDateUTC: string;
    lateRecogDateUTC: string;
    completeDateUTC?: string;
}
