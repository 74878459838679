<div class="d-flex flex-column justify-content-between">

    @if (task().taskTemplate.taskType === 'Assessment') {
      @if (!isDirectManager()) {
        <ng-container *ngTemplateOutlet="noPermissionHint"></ng-container>
      }
    <div class="children-horizontal-gap-4 overflow-y-auto p-8" [style.max-height]="maxHeight()">
        @for (question of questions(); track question.questionID; let i = $index) {
        <div class="card">
            <div class="card-header py-2  border-bottom">
                <p class="fw-semibold mb-3">{{(i + 1) + '. ' + question.text}}</p>
                <p class="text-neutral-700 f-small">{{getQuestionTypeDisplay(question.qType)}}</p>
            </div>
            <div class="card-body children-horizontal-gap-4">
                <!-- @if (question.options) {
                        <div class="d-flex gap-4">
                            <p class="f-small fw-semibold">Options</p>
                            <p class="f-small">{{question.options?.join(', ')}}</p>
                        </div>
                    } -->
                @if (task().taskTemplate?.completionType !== 'Manager') {
                <div class="d-flex gap-4">
                    <p class="f-small fw-semibold">Reviewee's<br>Response</p>
                    <p class="f-small">{{orderedResponses()[i]}}
                        @if (question.qType === 'ratings') {
                        <span> (1 to 10)</span>
                        }
                    </p>
                </div>
                }

                <div class="d-flex gap-4">
                    <p class="f-small fw-semibold">Manager's<br>Comment</p>

                    @if (task().managerComment && !isDraft()) {
                      @if(orderedComments()[i]?.responseText){
                      <!-- <p class="f-small">{{orderedComments()[i]?.responseText}}</p> -->
                      <p class="f-small" [innerHTML]="orderedComments()[i]?.responseText | htmlSanitizer: 'html'"></p>
                      } @else {
                      <i class="f-small text-neutral-700">No comment from the manager</i>
                      }
                    } @else {
                      @if(canComment()) {
                      <ckeditor class="f-small w-100" [id]="'response' + i" [formControl]="commentForms()?.controls[i]"
                          [config]="EDITOR_CONFIG"></ckeditor>
                      } @else {
                      <textarea [formControl]="commentForms()?.controls[i]" class="form-control f-small" rows="3"
                          id="description" placeholder="Enter comment..."></textarea>
                      }
                    }
                </div>
            </div>
        </div>
        }

    </div>
    } @else {
    <!-- meeting -->
     @switch (task().status) {
      @case (TASK_ASSIGN_STATUS_ENUM.skipped) {
        <div class="p-8">
          <p class="f-small text-neutral-700 fst-italic">No responses are available as the meeting was skipped.</p>
        </div>
      }
      @case (TASK_ASSIGN_STATUS_ENUM.open) {
        <ng-container *ngTemplateOutlet="editablePart"></ng-container>
      }
      @case (TASK_ASSIGN_STATUS_ENUM.overdue) {
        <ng-container *ngTemplateOutlet="editablePart"></ng-container>
      }
      @default {
        <ng-container *ngTemplateOutlet="notEditablePart"></ng-container>
      }
     }

     <ng-template #editablePart>
        @if (isDirectManager()) {
          <div class="p-8">
            <p class="fw-semibold mb-4">Talking Points</p>
            Editable part here!
            <app-performance-review-meeting-notes-form
              #PerformanceReviewMeetingNotesFormComponent
              [questions]="originalQuestionsData()"
              [managerComment]="originalManagerCommentData()"
              [hasComment]="true"
              [hasAdditionalNotes]="true"
              (allFormsValid)="onFormsValidityChange($event)"
            ></app-performance-review-meeting-notes-form>
          </div>
        } @else {
          <ng-container *ngTemplateOutlet="noPermissionHint"></ng-container>
          <ng-container *ngTemplateOutlet="notEditablePart"></ng-container>
        }
     </ng-template>

     <ng-template #notEditablePart>
      <div class="p-8">
        <p class="fw-semibold mb-4">Talking Points</p>
        Not Editable part here!
        <app-performance-review-meeting-notes-form
          #PerformanceReviewMeetingNotesFormComponent
          [questions]="originalQuestionsData()"
          [managerComment]="originalManagerCommentData()"
          [hasComment]="true"
          [hasAdditionalNotes]="true"
          [disabled]="true"
          (allFormsValid)="onFormsValidityChange($event)"
        ></app-performance-review-meeting-notes-form>
      </div>
     </ng-template>

    <!-- <div class="grid-halfs">
        <div>
            <h4 class="mb-4">Meeting Agenda</h4>
            <div class="f-small text-neutral-900 card p-4 meeting-card-height"
                [innerHTML]="task().taskTemplate?.description | htmlSanitizer: 'html'"></div>

        </div>
        <div>
            <div class="d-flex justify-content-between align-items-center mb-4">
                <h4 class="">Meeting Notes</h4>
                @if(pillText()) {
                <div class="rounded-pill px-2 fw-semibold bg-neutral-200 border f-small">{{pillText()}}</div>
                }

            </div>
            @if(task().managerComment && !isDraft()) {
            <div class="card p-4 bg-neutral-100 meeting-card-height">{{orderedComments()[0]?.responseText}}</div>
            <div class="card p-4 bg-neutral-100 meeting-card-height"
                [innerHTML]="orderedComments()[0]?.responseText | htmlSanitizer: 'html'"></div>
            }
            @else {
              @if(canComment()) {
              <ckeditor class="f-small" id="content" [formControl]="commentForms()?.controls[0]"
                  [config]="editorConfig()"></ckeditor>
              } @else {
              <textarea [disabled]="true" [formControl]="commentForms()?.controls[0]"
                  class="form-control f-small meeting-card-height" id="description"
                  [placeholder]="meetingNotePlaceholder()"></textarea>
              }
            }

        </div>
    </div> -->
    }
</div>

<ng-template #noPermissionHint>
  <div class="no-permisson-banner">
    <div class="me-4">
      <ui-streamline-icon class="me-1" [slIcon]="{name: 'lock-1', theme: 'bold', color: 'fill-neutral-900',size:'sm'}"></ui-streamline-icon>
    </div>
    <div>
      <p class="fw-semibold f-small">You don’t have permission to add notes to this meeting </p>
      <p class="text-neutral-700 f-small">Only {{managerFullName()}} add notes as a manager</p>
    </div>
  </div>
 </ng-template>
