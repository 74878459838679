import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { map } from 'underscore';

@Component({
  selector: 'app-bar-square-rating',
  standalone: true,
  imports: [],
  templateUrl: './bar-square-rating.component.html',
  styleUrl: './bar-square-rating.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BarSquareRatingComponent),
      multi: true
    }
  ],
})
export class BarSquareRatingComponent {
  @Input() minText?: string;
  @Input() disabled = false;
  @Input() maxText?: string;
  @Input() theme: string;
  @Input() max: number;
  /** Current rating. Can be a decimal value like 3.14 */
  @Input() rate;

  units: {
    selected: boolean;
    active: boolean;
    value: Number;
    contain: Number;

  }[];

  value: Number;

  // Function to call when the value changes.
  onChange = (_: Number) => { };
  // Function to call when the input is touched
  onTouched = () => { };


  ngOnInit() {
    // console.log('Bar Rating initalise');
    this.units = [];
    for (let i = 1; i <= this.max; i++) {
      this.units.push({
        selected: false,
        active: false,
        value: i,
        contain: i
      });
    }
    // this.writeValue(this.rate);
  }

  private updateState() {
    /** Set the rating */
    map(this.units, u => {
      u.selected = this.value === u.value;
      u.active = u.value === this.value;
    });
  }

  handleClick(value) {
    if (!this.disabled) {
      this.writeValue(value);
    }
  }

  // Can be used in displaying process in the future
  handleEnter(enter) {
    // console.log('🙇‍♀️ENTER POINT', enter);
    if (!this.disabled) {
      /** Add selected class for rating hover */
      map(this.units, (u) => {
        u.active = u.value <= enter;
        u.selected = false;
      });
    }
  }

  // Can be used in displaying process in the future
  /** Reset rate value */
  reset() {
    // console.log('NEED TO BE RESET', this.value);
    // this.leave.emit(this.nextRate);
    this.updateState();
  }

  // Allows Angular to update the model.
  // Update the model and changes needed for the view here.
  writeValue(value: Number): void {
    // console.log('write value', value);
    this.value = value || null;
    this.onChange(value);
    this.updateState();
  }

  // Allows Angular to register a function to call when the model (rating) changes.
  // Save the function as a property to call later here.
  registerOnChange(fn: (value: Number) => void): void {
    this.onChange = fn;
  }

  // Allows Angular to register a function to call when the input has been touched.
  // Save the function as a property to call later here.
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
