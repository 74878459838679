import { CommonModule } from '@angular/common';
import { Component, computed, inject, input, output, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ProcessingButtonComponent } from '@ui/buttons/processing-button/processing-button.component';
import * as dayjs from 'dayjs';
import { isEqual } from 'lodash';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { IPerformanceReviewAssignFull, ITablePerformanceReviewAssignFull, PerformanceReviewService, TASK_ASSIGN_STATUS_ENUM, TASK_TEMPLATE_ENUM, TASK_TEMPLATE_STATUS, TaskQuestionType, TEMPLATE_QUESTION_TYPE_OPTIONS } from 'src/app/services/performance-review.service';
import { UserService } from 'src/app/services/user.service';
import { CKEditorComponent } from 'src/app/shared-modules/ckeditor4-angular/ckeditor.component';
import { DisableAndShowTextDirective } from 'src/app/shared/directives/disable-and-show-text.directive';
import { HtmlSanitizerPipe } from 'src/app/shared/pipes/html-sanitizer.pipe';
import { LocalDatePipe } from 'src/app/shared/pipes/local-date.pipe';
import { SHARED_MODAL_CONFIRM_KEY, SHARED_MODAL_SAVE_KEY, SharedConfirmationModalComponent } from 'src/app/shared/popups/shared-confirmation-modal/shared-confirmation-modal.component';
import { ConfirmationModal } from 'src/app/shared/utils/base-class/confirmation-modal';
import { EDITOR_CONFIG_SIMPLE } from 'src/config/ckeditor-config';
import { UIStreamlineIconComponent } from "../../../../../../../ui/streamline-icon/ui-streamline-icon.component";
import { PerformanceReviewMeetingNotesFormComponent } from '../../../../tasks/shared/performance-review-meeting-notes-form/performance-review-meeting-notes-form.component';

@Component({
  selector: 'app-task-manager-input',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CommonModule, ProcessingButtonComponent, HtmlSanitizerPipe,
    DisableAndShowTextDirective, NgbTooltip, CKEditorComponent, UIStreamlineIconComponent, PerformanceReviewMeetingNotesFormComponent],
  templateUrl: './task-manager-input.component.html',
  styleUrl: './task-manager-input.component.scss'
})
export class TaskManagerInputComponent extends ConfirmationModal {
  task = input<ITablePerformanceReviewAssignFull>();
  modalService = inject(NgbModal);

  taskStatusChanged = output<IPerformanceReviewAssignFull>()
  validForms = output<boolean>();
  template = computed(() => this.task().taskTemplate)
  showCloseButton = input(false);
  maxHeight = input('');
  TASK_TEMPLATE_STATUS = TASK_TEMPLATE_STATUS;
  TASK_ASSIGN_STATUS_ENUM = TASK_ASSIGN_STATUS_ENUM;
  EDITOR_CONFIG = EDITOR_CONFIG_SIMPLE;
  editorConfig = computed(() => ({ ...EDITOR_CONFIG_SIMPLE, height: '48vh' }));
  isDirectManager = computed(() => this.task().managerUserID === this.userService.plainOrgUser.userID);
  managerFullName = computed(() => this.task().taskManager?.fullName);

  answeredVersion = computed(() => {
    const allVersions = (this.template().pastVersions || []).concat(this.template().currentVersion)
    const usedVersion = this.task().response?.version || this.task().managerComment?.version;
    return usedVersion ? allVersions.find(v => v.version === usedVersion) || this.template().currentVersion
      : this.template().currentVersion
  })

  questions = computed(() => this.answeredVersion()?.taskObjects?.map(objects => objects.questions).flat().sort((a, b) => a.order - b.order));

  isScheduled = computed(() => this.task().status === TASK_ASSIGN_STATUS_ENUM.scheduled);
  isSkipped = computed(() => this.task().status === TASK_ASSIGN_STATUS_ENUM.skipped);
  isCompleted = computed(() => this.task().status === TASK_ASSIGN_STATUS_ENUM.completed);

  isDraft = computed(() => this.task().managerComment && !this.task().managerComment?.responseDate);
  canComment = computed(() => this.isDraft() || (this.task().managerUserID === this.userService.plainOrgUser.userID && !this.isScheduled() && !this.isSkipped() && !this.isCompleted()));
  meetingNotePlaceholder = computed(() => {
    if (this.isSkipped()) {
      return 'No responses are available as the meeting was skipped.';
    }
    return 'Enter Notes';
  });
  orderedResponses = computed(() => this.questions()
    .map(q => {
      const response = this.task().response?.responses?.find(r => r.questionID === q.questionID);
      if (!response) {
        return 'Meeting not yet open. Available from ' + this.localDatePipe.transform(this.task()?.openDate);
      }
      if (response.answerText) {
        if (q.qType === 'date-picker') {
          return dayjs(response.answerText).format('DD/MM/YYYY')
        }
        return response.answerText;
      }
      if (!q.options?.length) {
        return ''
      }
      if (response?.answerOption || response?.answerOption === 0) {
        return q.options[+response?.answerOption] || response?.answerOption
      }
      if (response.multipleAnswers?.length) {
        return response.multipleAnswers.map(r => q.options[r])?.join(', ');
      }
    }));

  orderedComments = computed(() => this.questions().map(q => this.task().managerComment?.responses?.find(r => r.questionID === q.questionID)));

  commentForms = computed(() => {
    return new FormArray(this.questions()?.map((e,index) => {
      return new FormControl({ value: this.task().managerComment?.responses[index]?.responseText, disabled: !this.canComment() })}))
  });
  pillText = computed(() => {
    if (this.task().managerComment && !this.isDraft()) {
      return 'Notes Submitted - View Only';
    }
    if (this.isScheduled()) {
      return 'Meeting not yet open. Available from ' + this.localDatePipe.transform(this.task()?.openDate);
    }
    if (this.isSkipped()) {
      return 'Meeting Skipped';
    }
    if (!this.isDirectManager()) {
      return 'Only this user’s manager can add meeting notes';
    }
  });

  performanceReviewService = inject(PerformanceReviewService);
  userService = inject(UserService);
  localDatePipe = inject(LocalDatePipe);
  broadcastService = inject(BroadcastService);

  originalQuestionsData = computed(() => this.task().taskTemplate.currentVersion.taskObjects[0].questions);
  originalManagerCommentData = computed(() => this.task().managerComment);


  @ViewChild('PerformanceReviewMeetingNotesFormComponent') performanceReviewMeetingNotesFormComponent!: PerformanceReviewMeetingNotesFormComponent;

  submit() {
    this.isLoading = true;

    let payload;
    if (this.template().taskType === TASK_TEMPLATE_ENUM.assessment) {
      payload = {
        version: this.answeredVersion().version,
        responses: this.commentForms().value.map((val, i) => {
          return {
            questionID: this.questions()[i].questionID,
            responseText: val
          }
        })
      }
    }

    if (this.template().taskType === TASK_TEMPLATE_ENUM.meeting) {
      const processedData = this.performanceReviewMeetingNotesFormComponent?.getProcessedData();

      const now = new Date();
      const formattedDate = now.toISOString().split('.')[0];

      payload = {
        version: this.task()?.taskTemplate?.currentVersion?.version,
        responseDate: formattedDate,
        ...processedData,
      };
    }

    console.log('😯payload', payload);

    this.performanceReviewService.addComment(this.task().taskID, payload)
      .subscribe(task => {
        this.taskStatusChanged.emit({ ...task.task, managerComment: task.managerResponse });
        this.performanceReviewService.updateCachedMyTasks(task.task);
        const message = (this.task().status !== TASK_ASSIGN_STATUS_ENUM.completed && task.task?.status === TASK_ASSIGN_STATUS_ENUM.completed) ? 'Marked as complete' : 'Comments Added';
        this.confirmSuccess(message, task.task);
        this.broadcastService.broadcast('performance-task-updated');
      }, error => this.error(error))
  }


  openSaveAsDraftModal() {
    this.isLoading = true
    const activeModal = this.modalService.open(SharedConfirmationModalComponent, { backdrop: 'static', size: 'md' });
          activeModal.componentInstance.title = 'Save as draft?';
          activeModal.componentInstance.content = `<p>Saving as a draft will allow you to access your notes again when you need to.</p>`;
          activeModal.componentInstance.buttonText = 'Save as Draft';
          // activeModal.componentInstance.buttonClass = 'danger';
          activeModal.result.then((result) => {
            if(result === 'confirm'){
              this.saveDraft();
            }
    })
  }

  saveDraft(){
    let payload;
    if (this.template().taskType === TASK_TEMPLATE_ENUM.assessment) {
      payload = {
        version: this.answeredVersion().version,
        responses: this.commentForms().value.map((val, i) => {
          return {
            questionID: this.questions()[i].questionID,
            responseText: val
          }
        })
      }
    }

    if (this.template().taskType === TASK_TEMPLATE_ENUM.meeting) {
      const processedData = this.performanceReviewMeetingNotesFormComponent?.getProcessedData();

      const now = new Date();
      const formattedDate = now.toISOString().split('.')[0];

      payload = {
        version: this.task()?.taskTemplate?.currentVersion?.version,
        responseDate: formattedDate,
        ...processedData,
      };
    }

    console.log('😯payload', payload);

    this.performanceReviewService.saveDraftComment(this.task().taskID, payload).subscribe(task => {
      this.confirmSuccess('Draft saved!', task.task);
      this.taskStatusChanged.emit({ ...task.task, managerComment: task.managerResponse });
      this.performanceReviewService.updateCachedMyTasks(task.task);
      // const message = (this.task().status !== TASK_ASSIGN_STATUS_ENUM.completed && task.task?.status === TASK_ASSIGN_STATUS_ENUM.completed) ? 'Marked as complete' : 'Comments Added';
      this.broadcastService.broadcast('performance-task-updated');
    }, error => this.error(error))
  }

  getQuestionTypeDisplay(type: TaskQuestionType) {
    if (type === 'ratings') {
      return 'Rating';
    }
    return TEMPLATE_QUESTION_TYPE_OPTIONS.find(o => o.value === type).display
  }


  // ask for confirmation if commenting in progress
  onCloseClicked() {
    // const responseTexts = this.task().managerComment?.responses?.map(r => r?.responseText);
    // const formTexts = this.commentForms()?.value;
    // !isEqual(responseTexts, formTexts)
    if (this.canComment()) {
      const modal = this.modalService.open(SharedConfirmationModalComponent);
      (modal.componentInstance as SharedConfirmationModalComponent).configPack = {
        title: 'Discard changes?',
        content: 'Your changes will be permanently lost if discarded.',
        buttonText: 'Discard',
        buttonClass: 'danger'
      }
      modal.componentInstance.cancelButtonText = 'Close';
      modal.componentInstance.hasSaveButton = true;
      modal.result.then(res => {
        if (res === SHARED_MODAL_CONFIRM_KEY) {
          this.activeModal.dismiss();
        }

        if(res === SHARED_MODAL_SAVE_KEY){
          this.saveDraft();
          this.activeModal.close();
        }
      })
    } else {
      this.dismiss()
    }
  }

  onFormsValidityChange(isValid: boolean) {
    this.validForms.emit(isValid);
  }
}
function HostListener(arg0: string, arg1: string[]): (target: TaskManagerInputComponent, propertyKey: "task") => void {
  throw new Error('Function not implemented.');
}

